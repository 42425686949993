import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import { DIMENSIONS } from "../../constants";
import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  headerWrapper: {
    backgroundColor: "#FCF4E7",
    height: "100px",
    width: "100%",
    display: "flex",
    zIndex: 3000,
    justifyContent: "center",
    alignItems: "center",
  },
  headerContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: "flex",
    flexDirection: "row",
    gap: 5,
    [themeBreakpoints.down("md")]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down("sm")]: {
      padding: "20px 10px",
    },
  },
  navigationBox: {
    display: "flex",
    alignItems: "center",
  },
  linkStyle: {
    width: "auto",
    textDecoration: "none",
    color: "#151B25",
    "&:hover": {
      color: "#FFA101",
    },
  },
}));

export default styles;
