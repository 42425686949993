/* eslint-disable no-undef */

import { axiosClient } from "./client";

const qs = require("qs");

export const getProductByID = (ID) => {
  const query = qs.stringify(
    {
      populate: "*",
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
  return axiosClient.get(`api/products/${ID}?${query}`);
};

export const getProductsByCategory = (category_id, index, sortArray) => {
  const query = qs.stringify(
    {
      populate: "*",
      filters: {
        category: {
          id: {
            $eq: category_id,
          },
        },
      },
      sort: sortArray,
      pagination: {
        pageSize: 12,
        page: index,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return axiosClient.get(`api/products?${query}`);
};

export const getProductsByMultiCategory = (categories, index, sortArray) => {
  let filter = ``;
  categories.map((id, index2) => {
    filter += `filters[$or][${index2}][category][id][$eq]=${id}&`;
  });

  const query = qs.stringify(
    {
      populate: "*",
      sort: sortArray,
      pagination: {
        pageSize: 12,
        page: index,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return axiosClient.get(`api/products?${filter}&${query}`);
};

export const getProductsBySeach = (find, index, sortArray) => {
  let filter = `filters[$or][0][name][$containsi]=${find}&filters[$or][1][category][name][$containsi]=${find}`;

  const query = qs.stringify(
    {
      populate: "*",
      sort: sortArray,
      pagination: {
        pageSize: 12,
        page: index,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return axiosClient.get(`api/products?${filter}&${query}`);
};

export const getLatestProducts = () => {
  const query = qs.stringify(
    {
      populate: "*",
      sort: ["createdAt:desc"],
      pagination: {
        pageSize: 10, //To define
        page: 1,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
  return axiosClient.get(`api/products?${query}`);
};

export const getFeaturedProducts = (pageSize, index) => {
  const query = qs.stringify(
    {
      populate: "*",
      filters: {
        featured: {
          $eq: true,
        },
      },
      pagination: {
        pageSize: pageSize, //To define
        page: index,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
  return axiosClient.get(`api/products?${query}`);
};

export const getPath = (ID) => {
  const query = qs.stringify(
    {
      fields: ["category_id", "product_id"],
      filters: {
        product_id: {
          id: {
            $eq: ID,
          },
        },
      },
      populate: {
        category_id: {
          fields: ["name", "category_parent"],
          populate: {
            category_parent: {
              fields: ["name"],
            },
          },
        },
        product_id: {
          fields: ["id", "name"],
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
  return axiosClient.get(`api/category-products/?${query}`);
};

export const getPathLevels = (query) => {
  const response = query.data.data[0];
  const result = {
    path: [
      response.attributes.category_id.data.attributes.category_parent.data
        .attributes.name,
      response.attributes.category_id.data.attributes.name,
    ],
  };
  return result;
};
