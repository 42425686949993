import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  mainWrapper: {
    paddingTop: "80px",
    paddingLeft: "50px",
    paddingRight: "50px",
    [themeBreakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  carrouselHeader: {
    height: "400px",
  },
  carrouselHeaderImg: {
    height: "400px",
    objectFit: "cover",
    [themeBreakpoints.down("sm")]: {
      height: "200px",
    },
  },
  carrouselParentBox: {
    margin: "50px 0",
  },
}));

export default styles;
