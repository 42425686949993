import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

import breakpoints from "./breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const typography = {
  h1: {
    fontSize: 40,
    fontWeight: 700,
    [themeBreakpoints.down("md")]: {
      fontSize: 20,
    },
    fontFamily: ["Montserrat"].join(","),
  },
  h2: {
    fontSize: 30,
    fontWeight: 700,
    [themeBreakpoints.down("md")]: {
      fontSize: 18,
    },
    fontFamily: ["Montserrat"].join(","),
  },
  h3: {
    fontSize: 20,
    fontWeight: 600,
    [themeBreakpoints.down("md")]: {
      fontSize: 15,
    },
    fontFamily: ["Montserrat"].join(","),
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    color: "#151B25",
    fontFamily: ["Montserrat"].join(","),
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    color: "#fff",
    fontFamily: ["Montserrat"].join(","),
  },
};

export default typography;
