const breakpoints = {
  type: "ligth",
  primary: {
    main: "#0077FF",
  },
  secondary: {
    main: "#0C4160",
  },
  grey: {
    main: "#21B6A8",
  },
  white: {
    main: "#FFFFFF",
  },
};

export default breakpoints;
