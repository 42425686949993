import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import { DIMENSIONS } from "../../constants/";
import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  navbarWrapper: {
    backgroundColor: "white",
    height: "80px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex: 3000,
    position: "sticky",
    top: 40,
    [themeBreakpoints.down("md")]: {
      height: "120px",
    },
    [themeBreakpoints.down("sm")]: {
      height: "100%",
    },
  },
  navbarShadow: {
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
  },
  navbarLine: {
    borderBottom: "solid 1px rgba(0, 0, 0, 0.1)",
  },
  navbarContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [themeBreakpoints.down("md")]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down("sm")]: {
      padding: "20px 10px",
    },
  },
  logoBox: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "130px",
    [themeBreakpoints.down("sm")]: {
      width: "150px",
      paddingTop: "3px",
    },
  },
  itemsBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "25px",
    [themeBreakpoints.down("md")]: {
      height: "50px",
      justifyContent: "flex-start",
    },
  },
  navbarItem: {
    fontFamily: ["Montserrat"].join(","),
    fontSize: "16px",
    fontWeight: "700",
    textDecoration: "none",
    color: "#151B25",
    "&:hover": {
      color: "#FFA101",
    },
  },
  navbarItemActive: {
    fontFamily: ["Montserrat"].join(","),
    fontSize: "16px",
    fontWeight: "600",
    textDecoration: "none",
    color: "#151B25",
    "&:hover": {
      fontWeight: "600",
      textDecoration: "none",
      color: "#151B25",
    },
  },
  iconsBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "flex-end",
  },
  listDrawerBox: {
    width: "250px",
    marginTop: "115px",
  },
  mobileProductsBox: {
    margin: "20px",
  },
}));

export default styles;
