/* eslint-disable react/prop-types */

import { Box, Grid } from "@mui/material";
import Slide from "@mui/material/Slide";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import fontsStyles from "../../styles/fontStyles.js";
import styles from "./styles.js";

const NavbarCategories = ({
  categories,
  boxReference,
  openCategories,
  handlerCloseCategoriesState,
}) => {
  const classes = styles();
  const fontClasses = fontsStyles();

  return (
    <Slide
      direction="down"
      in={openCategories}
      container={boxReference.current}
      timeout={500}
      onMouseLeave={handlerCloseCategoriesState}
    >
      <Box className={classes.navbarWrapper}>
        <Box className={classes.navbarContentWrapper}>
          <Grid container>
            {categories.map((category, index) => (
              <Grid item md={2} key={index}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/category/${category.id}`}
                  reloadDocument
                >
                  <p
                    className={clsx(
                      fontClasses.h4,
                      fontClasses.navbarCategoryTitle
                    )}
                  >
                    {category.title}
                  </p>
                </Link>

                <Box sx={{ marginTop: "25px" }}>
                  {category.items.map((item, index) => (
                    <Link
                      className={fontClasses.navbarCategoryItem}
                      key={index}
                      to={`/category/${item.id}`}
                      reloadDocument
                    >
                      <p>{item.name}</p>
                    </Link>
                  ))}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Slide>
  );
};

export default NavbarCategories;
