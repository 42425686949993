import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, Grid, IconButton } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { URL_IMAGES } from "../../constants";
import fontsStyles from "../../styles/fontStyles.js";
import CustomPrimaryButton from "../CustomPrimaryButton";
import styles from "./styles.js";

// eslint-disable-next-line react/prop-types
const Footer = () => {
  const classes = styles();
  const fontClasses = fontsStyles();

  return (
    <Box className={classes.footerWrapper}>
      <Box className={classes.footerContentWrapper}>
        <Grid container spacing={4}>
          <Grid item sm={12} md={12} lg={5}>
            <Box>
              <Link to="/">
                <img
                  className={classes.imgLogo}
                  src={URL_IMAGES.URL_LOGO_SEALABS_DARK}
                  alt="sealabs logo"
                />
              </Link>
              <p className={clsx(fontClasses.p, classes.logoText)}>
                Incrementa tu presencia digital con tu propio catálogo en línea.
              </p>
              <CustomPrimaryButton
                label={"Contactar a ventas"}
                icon={<ArrowForwardIcon />}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={2}>
            <h3 className={clsx(fontClasses.h3, classes.categoryTitle)}>
              Categorias
            </h3>
            <Link className={fontClasses.footerCategoryItem}>
              <p>Novedades</p>
            </Link>
            <Link className={fontClasses.footerCategoryItem}>
              <p>Mas vendidos</p>
            </Link>
            <Link className={fontClasses.footerCategoryItem}>
              <p>Temporada</p>
            </Link>
            <Link className={fontClasses.footerCategoryItem}>
              <p>Ofertas</p>
            </Link>
          </Grid>
          <Grid item sm={12} md={4} lg={2}>
            <h3 className={clsx(fontClasses.h3, classes.categoryTitle)}>
              Categorias
            </h3>
            <Link className={fontClasses.footerCategoryItem}>
              <p>Historia</p>
            </Link>
            <Link className={fontClasses.footerCategoryItem}>
              <p>Tiendas</p>
            </Link>
            <Link className={fontClasses.footerCategoryItem}>
              <p>Metodos de pago</p>
            </Link>
            <Link className={fontClasses.footerCategoryItem}>
              <p>Envíos</p>
            </Link>
          </Grid>
          <Grid item sm={12} md={4} lg={3}>
            <h3 className={clsx(fontClasses.h3, classes.categoryTitle)}>
              Atencion al cliente
            </h3>
            <Link className={fontClasses.footerCategoryItem}>
              <p>+506 88888888</p>
            </Link>
            <Link className={fontClasses.footerCategoryItem}>
              <p>hello@sealabs.cr</p>
            </Link>
            <Link className={fontClasses.footerCategoryItem}>
              <p>Localizador</p>
            </Link>
            <Link className={fontClasses.footerCategoryItem}>
              <p>Preguntas frecuentes</p>
            </Link>
          </Grid>
        </Grid>
        <Box className={classes.footerBoxRigths}>
          <Grid container>
            <Grid item sm={12} lg={4} order={{ sm: 2, md: 2, lg: 1 }}>
              <Box className={classes.footerInfoBox}>
                <p className={fontClasses.footerInfoText}>
                  All rights reserved Sealabs Technologies
                </p>
              </Box>
            </Grid>
            <Grid item sm={12} lg={4} order={{ sm: 1, md: 1, lg: 2 }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton size="medium">
                  <InstagramIcon />
                </IconButton>
                <IconButton size="medium">
                  <FacebookIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item sm={12} lg={4} order={{ sm: 3, md: 3, lg: 3 }}>
              <Box className={classes.footerInfoBox2}>
                <Link
                  className={clsx(
                    fontClasses.footerInfoText,
                    fontClasses.footerInfoTextLink
                  )}
                >
                  <p style={{ margin: 0 }}>Preguntas frecuentes</p>
                </Link>
                <p className={fontClasses.footerInfoText}>|</p>
                <Link
                  className={clsx(
                    fontClasses.footerInfoText,
                    fontClasses.footerInfoTextLink
                  )}
                >
                  <p style={{ margin: 0 }}>Términos y condiciones</p>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
