import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  productBox: {
    position: "relative",
    backgroundColor: "white",
    border: "1px solid #DCDCDC",
    height: "80px",
    width: "80px",
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
    [themeBreakpoints.down("sm")]: {
      "&:hover": {
        transform: "scale(1)",
      },
    },
  },
  imgStyle: {
    width: "100%",
    objectFit: "contain",
  },
}));

export default styles;
