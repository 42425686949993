// eslint-disable-next-line react/prop-types
import { Divider } from "@material-ui/core";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
//import LocalMallIcon from "@mui/icons-material/LocalMall";
import MenuIcon from "@mui/icons-material/Menu";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
//import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { URL_IMAGES } from "../../constants";
import fontsStyles from "../../styles/fontStyles.js";
import styles from "./styles.js";

const Navbar = ({
  categories,
  reference,
  handlerSearchBarState,
  openSearchBar,
  handlerOpenCategoriesState,
  openCategories,
}) => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const location = useLocation();
  const [pathname, setPathname] = useState("");
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    setPathname(location.pathname);
    closeDrawer();
  }, [location]);

  const openDrawer = () => {
    setDrawer(true);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  return (
    <Box
      className={clsx(
        classes.navbarWrapper,
        openSearchBar || openCategories
          ? classes.navbarLine
          : classes.navbarShadow
      )}
      ref={reference}
    >
      <Box className={classes.navbarContentWrapper}>
        <Grid container>
          <Grid item xs={6} md={6} lg={2}>
            <Box className={classes.logoBox}>
              <Box
                className={classes.navbarHamburgerMenuBox}
                sx={{
                  display: {
                    xs: "flex",
                    sm: "flex",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <IconButton aria-label="delete" onClick={openDrawer}>
                  <MenuIcon />
                </IconButton>
              </Box>
              <Link to="/">
                <img
                  style={{ width: "100%" }}
                  src={URL_IMAGES.URL_LOGO_SEALABS_DARK}
                  alt="sealabs logo"
                />
              </Link>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={8}
            order={{ lg: 2, md: 3 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            <Box className={classes.itemsBox}>
              <Link
                className={
                  pathname === ""
                    ? classes.navbarItemActive
                    : classes.navbarItem
                }
                to={""}
              >
                NOVEDADES
              </Link>
              <Link
                className={
                  pathname === ""
                    ? classes.navbarItemActive
                    : classes.navbarItem
                }
                to={""}
              >
                MÁS VENDIDOS
              </Link>
              <Link
                onMouseEnter={handlerOpenCategoriesState}
                className={
                  pathname === ""
                    ? classes.navbarItemActive
                    : classes.navbarItem
                }
                to={""}
              >
                PRODUCTOS
              </Link>
              <Link
                className={
                  pathname === ""
                    ? classes.navbarItemActive
                    : classes.navbarItem
                }
                to={""}
              >
                OFERTAS ESPECIALES
              </Link>
            </Box>
          </Grid>
          <Grid item xs={6} md={6} lg={2} order={{ lg: 3, md: 2 }}>
            <Box className={classes.iconsBox}>
              <IconButton onClick={handlerSearchBarState}>
                <SearchIcon sx={{ color: "#151B25" }} />
              </IconButton>
              {/** 
              <IconButton>
                <LocalMallIcon sx={{ color: "#151B25" }} />
              </IconButton>
              <IconButton>
                <PersonOutlineOutlinedIcon sx={{ color: "#151B25" }} />
              </IconButton>
              */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Drawer anchor="left" open={drawer} onClose={closeDrawer}>
          <Box className={classes.listDrawerBox}>
            <List>
              <ListItem disablePadding>
                <Link to={""} style={{ width: "100%", textDecoration: "none" }}>
                  <ListItemButton
                    sx={{
                      backgroundColor:
                        pathname === "/news" ? "#EFF5FF" : "white",
                    }}
                    t
                  >
                    <ListItemIcon
                      className={classes.listItemIcon}
                      sx={{ minWidth: "40px" }}
                    >
                      <NewReleasesIcon />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <p
                          className={fontClasses.p}
                          style={{ color: "#151B25", fontWeight: 500 }}
                        >
                          Novedades
                        </p>
                      }
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link to={""} style={{ width: "100%", textDecoration: "none" }}>
                  <ListItemButton
                    sx={{
                      backgroundColor:
                        pathname === "/top" ? "#EFF5FF" : "white",
                    }}
                    t
                  >
                    <ListItemIcon
                      className={classes.listItemIcon}
                      sx={{ minWidth: "40px" }}
                    >
                      <EmojiEventsIcon />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <p
                          className={fontClasses.p}
                          style={{ color: "#151B25", fontWeight: 500 }}
                        >
                          Mas Vendidos
                        </p>
                      }
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
            <Divider />
            <Box className={classes.mobileProductsBox}>
              {categories.map((category, index) => (
                <Box key={index} sx={{ marginBottom: "30px" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/category/${category.id}`}
                    reloadDocument
                  >
                    <p
                      className={clsx(
                        fontClasses.h4,
                        fontClasses.navbarCategoryTitle
                      )}
                    >
                      {category.title}
                    </p>
                  </Link>
                  <Box sx={{ marginTop: "10px" }}>
                    {category.items.map((item, index) => (
                      <Link
                        className={fontClasses.navbarCategoryItem}
                        key={index}
                        to={`/category/${item.id}`}
                        reloadDocument
                      >
                        <p>{item.name}</p>
                      </Link>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

export default Navbar;
