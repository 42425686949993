import { Box } from "@mui/material";
import React from "react";

import fontsStyles from "../../styles/fontStyles.js";
import styles from "./styles.js";

// eslint-disable-next-line react/prop-types
const InfoNavbar = ({ content }) => {
  const classes = styles();
  const fontClasses = fontsStyles();

  return (
    <Box className={classes.navbarWrapper}>
      <Box className={classes.navbarContentWrapper}>
        <p className={fontClasses.inforNavbarText}>{content}</p>
      </Box>
    </Box>
  );
};

export default InfoNavbar;
