import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import { DIMENSIONS } from "../../constants";
import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  categoriesWrapper: {
    backgroundColor: "white",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [themeBreakpoints.down("sm")]: {
      height: "100%",
      padding: "20px 0 20px 0",
    },
  },
  categoriesContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    margin: "80px 0",
    [themeBreakpoints.down("md")]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down("sm")]: {
      margin: "20px 0",
      padding: "20px 10px",
    },
  },
  quantityDisplayOrder: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    [themeBreakpoints.down("sm")]: {},
  },
  orderSelectBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [themeBreakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  paginationBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
  },
  notFoundBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "50px 0",
  },
  notFoundIcon: {
    width: "100%",
    textAlign: "center",
  },
}));

export default styles;
