import "react-responsive-carousel/lib/styles/carousel.min.css";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, IconButton, Skeleton } from "@mui/material";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import ItemsCarousel from "react-items-carousel";
import { useMediaQuery } from "react-responsive";

import fontsStyles from "../../styles/fontStyles.js";
import Product from "../Product/Product.js";
import styles from "./styles.js";

// eslint-disable-next-line react/prop-types
const ProductCarrouselLine = ({ title, productList }) => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [showCards, setShowCards] = useState(0);
  const [outsiteArrows, setOutsiteArrows] = useState(true);
  const chevronWidth = 40;
  const isDesktop = useMediaQuery({ query: "(min-width: 1100px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 800px)" });
  const isMobile = useMediaQuery({ query: "(min-width: 400px)" });

  useEffect(() => {
    if (isDesktop) {
      setShowCards(4);
      setOutsiteArrows(true);
    } else if (isTablet) {
      setShowCards(2);
      setOutsiteArrows(false);
    } else {
      setShowCards(1);
      setOutsiteArrows(false);
    }
  }, [isDesktop, isTablet, isMobile]);

  return (
    <Box className={classes.productCarrouselWrapper}>
      <Box className={classes.productCarrouselContentWrapper}>
        <h3 className={fontClasses.h3}>{title}</h3>
        <Box className={classes.sliderBox}>
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={showCards}
            gutter={0}
            outsideChevron={outsiteArrows}
            leftChevron={
              <IconButton aria-label="delete" size="large">
                <KeyboardArrowLeftIcon fontSize="inherit" />
              </IconButton>
            }
            rightChevron={
              <IconButton aria-label="delete" size="large">
                <KeyboardArrowRightIcon fontSize="inherit" />
              </IconButton>
            }
            chevronWidth={chevronWidth}
          >
            {productList?.length > 0 &&
              productList.map((item, index) => (
                <Box key={index} sx={{ padding: "12px", height: "450px" }}>
                  <Product
                    id={item.id}
                    img={
                      item.attributes.images != null
                        ? item.attributes.images[0]
                        : "https://res.cloudinary.com/sealabs/image/upload/v1692300837/store.sealabs.cr/template_ebn1jr.png"
                    }
                    title={item.attributes.name}
                    currency={item.attributes.currency.data.attributes.symbol}
                    price={item.attributes.price}
                    discount={item.attributes.discount}
                    createdDate={item.attributes.createdAt}
                  />
                </Box>
              ))}
            {productList?.length === 0 &&
              [1, 2, 3, 4].map((index) => (
                <Box key={index} sx={{ padding: "12px", height: "450px" }}>
                  <Skeleton height="450px" sx={{ transform: "scale(1)" }} />
                </Box>
              ))}
          </ItemsCarousel>
        </Box>
      </Box>
    </Box>
  );
};

ProductCarrouselLine.propTypes = {
  title: PropTypes.string,
  productList: PropTypes.array,
};

export default ProductCarrouselLine;
