import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import { DIMENSIONS } from "../../constants";
import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  productCarrouselWrapper: {
    backgroundColor: "white",
    paddingBottom: "50px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  productCarrouselContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    [themeBreakpoints.down("md")]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down("sm")]: {
      width: "90%",
    },
  },
  sliderBox: {
    marginTop: "25px",
  },
}));

export default styles;
