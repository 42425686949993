import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  productBox: {
    position: "relative",
    backgroundColor: "white",
    border: "solid 1px #FAFBFC",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    height: "430px",
    width: "100%",
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
    [themeBreakpoints.down("sm")]: {
      "&:hover": {
        transform: "scale(1)",
      },
    },
  },
  imgStyle: {
    width: "100%",
    height: "340px",
    objectFit: "contain",
  },
  infoBox: {
    height: "90px",
    borderTop: "solid 1px #ECECEC",
    padding: "0px 15px 0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "column",
  },
  titleText: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    lineHeight: "18px",
    height: "36px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    fontWeight: 500,
    fontSize: 14,
  },
  pricingBox: {
    display: "flex",
    gap: 10,
  },
  priceDiscountText: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: "5px",
    color: "#BDBDBD",
    textDecoration: "line-through",
  },
  priceText: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: "5px",
    color: "#FFA101",
  },
  newTag: {
    position: "absolute",
    top: 15,
    left: 15,
    backgroundColor: "#0077FF",
    padding: "5px",
    borderRadius: "20px",
    width: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  discountTag: {
    position: "absolute",
    top: 15,
    left: 15,
    backgroundColor: "#F26659",
    padding: "5px",
    borderRadius: "20px",
    width: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tagText: {
    fontWeight: 500,
    fontSize: 16,
    color: "white",
  },
}));

export default styles;
