import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getCategoryById } from "../../api/category.js";
import { getProductByID } from "../../api/products.js";
import AlternativeProductOption from "../../components/AlternativeProductOption/index.js";
import ImageViewer from "../../components/ImageViewer/index.js";
import ProductButtonPage from "../../components/ProductButtonPage/index.js";
import ProductHeaderPage from "../../components/ProductHederPage/productHeader.js";
import { COLORS } from "../../constants/index.js";
import fontsStyles from "../../styles/fontStyles.js";
import { getProductPaths } from "../../utils/categories.js";
import { isNewProduct } from "../../utils/date.js";
import styles from "./styles.js";

//import ProductCarrouselLine from "../../components/ProductCarrouselLine/productCarrouselLine.js";

const ProductPage = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [tabIndex, setTabIndex] = React.useState(1);
  const [productData, setProductData] = useState();
  const [foundProductData, setFoundProductData] = useState();
  const [category, setCategory] = useState();
  const [categoryPath, setCategoryPath] = useState();
  const [imgIndex, setImgIndex] = useState(0);

  const HandlerClickImg = (index) => {
    setImgIndex(index);
  };

  useEffect(() => {
    if (!isNaN(id)) {
      getProductByID(id)
        .then(function (response) {
          setProductData(response.data.data);
          setFoundProductData(response.data.data.attributes);
          console.log(response.data.data.attributes);
        })
        .catch((error) => {
          console.log("error", error);
          if (error.response.status === 404) navigate("/not-found");
          else navigate("/server-error");
        });
    } else {
      navigate("/not-found");
    }
  }, []);

  useEffect(() => {
    if (productData) {
      setFoundProductData(productData.attributes);
      getCategoryById(productData.attributes.category.data.id)
        .then(function (response) {
          setCategory(response.data.data);
        })
        .catch((error) => {
          if (error.response.status === 404) navigate("/not-found");
          else navigate("/server-error");
        });
    }
  }, [productData]);

  useEffect(() => {
    if (category) {
      setCategoryPath(getProductPaths(category));
    }
  }, [category]);

  const handleChangeTabs = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const DisplayProducts = () => {
    return (
      <Box className={classes.productWrapper}>
        <Box className={classes.productontentWrapper}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={6} lg={6} order={{ xs: 2, md: 1, lg: 1 }}>
              <Box>
                <ImageViewer
                  imgs={
                    foundProductData.images
                      ? foundProductData.images
                      : [
                          "https://res.cloudinary.com/sealabs/image/upload/v1692300837/store.sealabs.cr/template_ebn1jr.png",
                        ]
                  }
                  imgIndex={imgIndex}
                  hanlderClickImg={HandlerClickImg}
                />
              </Box>
            </Grid>
            <Grid item sm={12} md={6} lg={6} order={{ xs: 1, md: 2, lg: 2 }}>
              <Box>
                <Box className={classes.tagsBox}>
                  {isNewProduct(foundProductData.createdAt) && (
                    <Box className={classes.newTag}>
                      <p className={clsx(fontClasses.p, classes.tagText)}>
                        NEW
                      </p>
                    </Box>
                  )}
                  {foundProductData.discount && (
                    <Box className={classes.discountTag}>
                      <p className={clsx(fontClasses.p, classes.tagText)}>
                        - {foundProductData.discount}%
                      </p>
                    </Box>
                  )}
                </Box>
                <h2 className={fontClasses.h2}>{foundProductData.name}</h2>
                <Box className={classes.spanInformationBox}>
                  <span
                    className={clsx(fontClasses.p, classes.spanInformation)}
                  >
                    SKU: {foundProductData.sku}
                  </span>
                  <span
                    className={clsx(fontClasses.p, classes.spanInformation)}
                  >
                    Stock: {foundProductData.stock}
                  </span>
                </Box>
                <Box className={classes.pricingBox}>
                  <h3
                    className={clsx(
                      fontClasses.h3,
                      foundProductData.discount
                        ? classes.discountPrice
                        : classes.price
                    )}
                  >
                    {`${foundProductData.currency.data.attributes.symbol} ${foundProductData.price}`}
                  </h3>
                  {foundProductData.discount && (
                    <h3 className={clsx(fontClasses.h3, classes.price)}>
                      {`${foundProductData.currency.data.attributes.symbol} ${foundProductData.price}`}
                    </h3>
                  )}
                </Box>
              </Box>
              {foundProductData.prinicipal_product.data && (
                <Box className={classes.alernativeOptionsBox}>
                  <p className={fontClasses.p}>Otras opciones</p>
                  <Box className={classes.alernativeOptionsContentBox}>
                    <AlternativeProductOption
                      id={"prueba"}
                      img={
                        "https://target.scene7.com/is/image/Target/GUEST_beb1271f-0c2c-41c2-b348-b11049499ed0?wid=488&hei=488&fmt=pjpeg"
                      }
                    />
                    <AlternativeProductOption
                      id={"prueba"}
                      img={
                        "https://funkospace.pe/cdn/shop/products/e7ba21caf5534f64969413b1569ed67exl_1200x1200.webp?v=1671046443"
                      }
                    />
                  </Box>
                </Box>
              )}
              <Box className={classes.actionButtonsBox}>
                <ProductButtonPage
                  label={"Solicitar por WhatsApp"}
                  icon={<WhatsAppIcon />}
                />
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.descriptionTabsBoxs}>
            <Box>
              <Tabs
                value={tabIndex}
                onChange={handleChangeTabs}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: COLORS.MAIN_COLOR,
                  },
                }}
              >
                {foundProductData.description && (
                  <Tab
                    label="Descripción"
                    value={1}
                    sx={{
                      "&.MuiTab-root": {
                        color: COLORS.BLACK,
                        textTransform: "capitalize",
                        borderColor: "red",
                        fontSize: 20,
                        fontWeight: 600,
                        fontFamily: ["Montserrat"].join(","),
                      },
                      "&.MuiTab-root:hover": {
                        color: COLORS.MAIN_COLOR,
                      },
                    }}
                  />
                )}
                {foundProductData.attributes && (
                  <Tab
                    label="Información"
                    value={2}
                    sx={{
                      "&.MuiTab-root": {
                        color: COLORS.BLACK,
                        textTransform: "capitalize",
                        borderColor: "red",
                        fontSize: 20,
                        fontWeight: 600,
                        fontFamily: ["Montserrat"].join(","),
                      },
                      "&.MuiTab-root:hover": {
                        color: COLORS.MAIN_COLOR,
                      },
                    }}
                  />
                )}
              </Tabs>
            </Box>
            {tabIndex == 1 && (
              <Box className={classes.informationBox}>
                <p className={fontClasses.p}>{foundProductData.description}</p>
              </Box>
            )}
            {tabIndex == 2 && (
              <Box className={classes.informationBox}>
                <Table aria-label="simple table">
                  <TableBody>
                    {Object.entries(foundProductData.attributes).map(
                      ([key, value]) => {
                        return (
                          <TableRow
                            key={key}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <b>{key}</b>
                            </TableCell>
                            <TableCell align="right">
                              {typeof value === "object"
                                ? JSON.stringify(value)
                                : value}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const LoadingProducts = () => {
    return (
      <Box className={classes.productWrapper}>
        <Box className={classes.productontentWrapper}>
          <Grid container spacing={8}>
            <Grid item sm={12} md={6} lg={6} order={{ xs: 2, md: 1, lg: 1 }}>
              <Skeleton
                width={"100%"}
                height={500}
                sx={{ transform: "scale(1)" }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={6} order={{ xs: 1, md: 2, lg: 2 }}>
              <Box>
                <Skeleton
                  width={"100%"}
                  height={40}
                  sx={{ transform: "scale(1)", marginBottom: "15px" }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  sx={{ transform: "scale(1)", marginBottom: "15px" }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  sx={{ transform: "scale(1)", marginBottom: "15px" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <ProductHeaderPage paths={categoryPath} />
      {!foundProductData && !categoryPath && <LoadingProducts />}
      {foundProductData && <DisplayProducts />}
    </Box>
  );
};

export default ProductPage;
