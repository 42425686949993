import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import { DIMENSIONS } from "../../constants";
import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  productWrapper: {
    backgroundColor: "white",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [themeBreakpoints.down("sm")]: {
      height: "100%",
      padding: "20px 0 20px 0",
    },
  },
  productontentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    margin: "100px 0",
    [themeBreakpoints.down("md")]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down("sm")]: {
      margin: "20px 0",
      padding: "20px 10px",
    },
  },
  spanInformationBox: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "flex-start",
    gap: 20,
  },
  spanInformation: {
    fontSize: "12px",
    fontWeight: 300,
  },
  actionButtonsBox: {
    marginTop: "25px",
  },
  tagsBox: {
    display: "flex",
    gap: 10,
    marginBottom: "25px",
  },
  newTag: {
    backgroundColor: "#0077FF",
    padding: "5px",
    borderRadius: "20px",
    width: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  discountTag: {
    backgroundColor: "#F26659",
    padding: "5px",
    borderRadius: "20px",
    width: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tagText: {
    fontWeight: 500,
    fontSize: 16,
    color: "white",
  },
  pricingBox: {
    display: "flex",
    gap: 10,
  },
  price: {
    color: "#FFA101",
  },
  discountPrice: {
    color: "#BDBDBD",
    textDecoration: "line-through",
  },
  alernativeOptionsBox: {
    marginTop: "25px",
  },
  alernativeOptionsContentBox: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-start",
    gap: 15,
  },
  descriptionTabsBoxs: {
    marginTop: "100px",
    [themeBreakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  informationBox: {
    marginTop: "25px",
  },
}));

export default styles;
