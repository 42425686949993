import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import { DIMENSIONS } from "../../constants";
import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  navbarWrapper: {
    backgroundColor: "White",
    zIndex: 1000,
    width: "100%",
    padding: "50px 0 50px 0",
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
  },
  navbarContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: "flex",
    displayFlex: "row",
    alignItems: "center",
    gap: 10,
    [themeBreakpoints.down("md")]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down("sm")]: {
      padding: "20px 10px",
    },
  },
}));

export default styles;
