import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import { Link } from "react-router-dom";

import fontsStyles from "../../styles/fontStyles.js";
import styles from "./styles.js";

// eslint-disable-next-line react/prop-types
const ProductHeaderPage = ({ paths }) => {
  const classes = styles();
  const fontClasses = fontsStyles();

  return (
    <Box className={classes.headerWrapper}>
      {!paths && (
        <Box className={classes.headerContentWrapper}>
          <Skeleton width={300} height={20} sx={{ transform: "scale(1)" }} />
        </Box>
      )}
      {paths && (
        <Box className={classes.headerContentWrapper}>
          <Link className={classes.linkStyle}>
            <Box className={classes.navigationBox}>
              <HomeOutlinedIcon sx={{ paddingRight: "5px" }} />
              <p className={fontClasses.p}>Home</p>
            </Box>
          </Link>
          {paths.map((path, index) => (
            // eslint-disable-next-line react/jsx-key
            <Box key={index} className={classes.navigationBox}>
              <Box className={classes.navigationBox}>
                <ArrowForwardIosOutlinedIcon
                  sx={{ fontSize: 14, paddingRight: "5px" }}
                />
              </Box>
              <Link className={classes.linkStyle} to={`/category/${path.id}`}>
                <Box className={classes.navigationBox}>
                  <p className={fontClasses.p}>{path.label}</p>
                </Box>
              </Link>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ProductHeaderPage;
