import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import breakpoints from "../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const fontsStyles = makeStyles(() => ({
  h1: {
    fontSize: 38,
    fontWeight: 600,
    color: "#151B25",
    lineHeight: "50px",
    fontFamily: ["Montserrat"].join(","),
    [themeBreakpoints.down("sm")]: {
      fontSize: 28,
      lineHeight: "33px",
    },
  },
  h2: {
    fontSize: 30,
    fontWeight: 600,
    color: "#151B25",
    lineHeight: "30px",
    fontFamily: ["Montserrat"].join(","),
    margin: 0,
    [themeBreakpoints.down("sm")]: {
      fontSize: 26,
      lineHeight: "36px",
    },
  },
  h3: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "26px",
    color: "#151B25",
    margin: "0px",
    fontFamily: ["Montserrat"].join(","),
    [themeBreakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  h4: {
    fontSize: 20,
    fontWeight: 600,
    color: "#151B25",
    lineHeight: "22px",
    margin: "0px",
    fontFamily: ["Montserrat"].join(","),
    [themeBreakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  p: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: ["Montserrat"].join(","),
    [themeBreakpoints.down("sm")]: {
      fontSize: 15,
      margin: 0,
    },
  },
  inforNavbarText: {
    margin: 0,
    fontSize: 14,
    fontWeight: 700,
    color: "white",
    lineHeight: "16px",
    fontFamily: ["Montserrat"].join(","),
  },
  navbarCategoryTitle: {
    "&:hover": {
      color: "#FFA101",
    },
  },
  navbarCategoryItem: {
    margin: 0,
    fontSize: 14,
    fontWeight: 500,
    color: "#151B25",
    lineHeight: "16px",
    textDecoration: "none",
    marginBottom: "10px",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#FFA101",
    },
  },
  footerCategoryItem: {
    margin: 0,
    fontSize: 16,
    fontWeight: 500,
    color: "#151B25",
    lineHeight: "18px",
    textDecoration: "none",
    marginBottom: "15px",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#FFA101",
    },
  },
  primaryButtonText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    color: "#151B25",
    fontFamily: ["Montserrat"].join(","),
    [themeBreakpoints.down("sm")]: {
      fontSize: 15,
      margin: 0,
    },
  },
  footerInfoText: {
    margin: 0,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: ["Montserrat"].join(","),
    textDecoration: "none",
  },
  footerInfoTextLink: {
    "&:hover": {
      color: "#FFA101",
    },
  },
  productButtonPage: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    color: "white",
    fontFamily: ["Montserrat"].join(","),
    [themeBreakpoints.down("sm")]: {
      fontSize: 15,
      margin: 0,
    },
  },
}));

export default fontsStyles;
