/* eslint-disable react/prop-types */

import { Box } from "@mui/material";
import React from "react";

import styles from "./styles.js";

const ImageViewer = ({ imgs, imgIndex, hanlderClickImg }) => {
  const classes = styles();

  return (
    <Box>
      <Box className={classes.imgBox}>
        <img src={imgs[imgIndex]} className={classes.imgContent} />
      </Box>
      <Box className={classes.thumbBox}>
        {imgs.map((img, index) => (
          <Box
            key={index}
            className={classes.thumbImgBox}
            onClick={() => hanlderClickImg(index)}
          >
            <img src={img} className={classes.thumImg} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImageViewer;
