import { Box } from "@mui/material";
import React from "react";

import fontsStyles from "../../styles/fontStyles.js";
import styles from "./styles.js";

// eslint-disable-next-line react/prop-types
const SearchHeaderPage = ({ search }) => {
  const classes = styles();
  const fontClasses = fontsStyles();

  return (
    <Box className={classes.headerWrapper}>
      <Box className={classes.headerContentWrapper}>
        <p className={fontClasses.p}>
          <b>Resultados de la búsqueda: </b>
          <q>{search}</q>
        </p>
      </Box>
    </Box>
  );
};

export default SearchHeaderPage;
