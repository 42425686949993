/* eslint-disable react/prop-types */

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Slide from "@mui/material/Slide";
import React from "react";

import fontsStyles from "../../styles/fontStyles.js";
import styles from "./styles.js";

const SearchBarNav = ({
  boxReference,
  openSearchBar,
  handlerSearchBarState,
  navbarSearchText,
  handlerNavbarSearchText,
  handlerNavbarSearchEnter,
  searchInputRef,
}) => {
  const classes = styles();
  const fontClasses = fontsStyles();

  return (
    <Slide
      direction="down"
      in={openSearchBar}
      container={boxReference.current}
      timeout={500}
    >
      <Box className={classes.navbarWrapper}>
        <Box className={classes.navbarContentWrapper}>
          <p className={fontClasses.p}>
            {" "}
            <SearchIcon sx={{ marginTop: "8px" }} />
          </p>
          <InputBase
            className={fontClasses.p}
            sx={{ height: "40px", width: "100%" }}
            placeholder="Escriba algo para buscar"
            value={navbarSearchText}
            inputRef={searchInputRef}
            onChange={(e) => handlerNavbarSearchText(e.target.value)}
            onKeyUp={(ev) => {
              if (ev.key === "Enter") {
                handlerNavbarSearchEnter();
                ev.preventDefault();
              }
            }}
            inputProps={{
              style: {
                fontSize: 16,
                fontFamily: "Montserrat",
                color: "#151B25",
                fontWeight: 500,
              },
            }}
          />
          <IconButton aria-label="delete" onClick={handlerSearchBarState}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Slide>
  );
};

export default SearchBarNav;
