/* eslint-disable no-undef */

import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiToken = process.env.REACT_APP_API_TOKEN;

export const axiosClient = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiToken}`,
  },
});
