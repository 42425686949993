/* eslint-disable no-undef */

import { axiosClient } from "./client";

const qs = require("qs");

export const getCategoryById = (categoryId) => {
  const query = qs.stringify(
    {
      populate: "deep,4",
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
  return axiosClient.get(`api/categories/${categoryId}?${query}`);
};
