/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.js";

const AlternativeProductOption = ({ id, img }) => {
  const classes = styles();

  return (
    <Link
      to={`/product/${id}`}
      style={{ textDecoration: "none", color: "inherit" }}
      relative="path"
    >
      <Box className={classes.productBox}>
        <img src={img} className={classes.imgStyle} />
      </Box>
    </Link>
  );
};

export default AlternativeProductOption;
