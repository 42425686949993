import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  imgBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  imgContent: {
    height: "500px",
    objectFit: "contain",
    [themeBreakpoints.down("sm")]: {
      height: "300px",
    },
  },
  thumbBox: {
    display: "flex",
    flexWrap: "wrap",
    overflowX: "auto",
    overflowY: "hidden",
    padding: "10px 10px",
    width: "100%",
    marginTop: "15px",
    alignItems: "start",
    gap: 10,
  },
  thumbImgBox: {
    backgroundColor: "white",
    border: "1px solid #DCDCDC",
    height: "80px",
    width: "80px",
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
    [themeBreakpoints.down("sm")]: {
      "&:hover": {
        transform: "scale(1)",
      },
    },
  },
  thumImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

export default styles;
