/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import fontsStyles from "../../styles/fontStyles.js";
import { isNewProduct } from "../../utils/date.js";
import { getDiscountPrice } from "../../utils/price.js";
import styles from "./styles.js";

const Product = ({
  id,
  img,
  title,
  price,
  currency,
  createdDate,
  discount,
}) => {
  const classes = styles();
  const fontClasses = fontsStyles();

  return (
    <Link
      to={`/product/${id}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Box className={classes.productBox}>
        <img src={img} className={classes.imgStyle} />
        <Box className={classes.infoBox}>
          <p className={clsx(fontClasses.p, classes.titleText)}>{title}</p>
          <Box className={clsx(classes.pricingBox)}>
            <p
              className={clsx(
                fontClasses.p,
                discount ? classes.priceDiscountText : classes.priceText
              )}
            >{`${currency} ${price}`}</p>
            {discount && (
              <p className={clsx(fontClasses.p, classes.priceText)}>
                {`${currency} ${getDiscountPrice(price, discount)}`}
              </p>
            )}
          </Box>
        </Box>
        {isNewProduct(createdDate) && !discount && (
          <Box className={classes.newTag}>
            <p className={clsx(fontClasses.p, classes.tagText)}>NEW</p>
          </Box>
        )}
        {discount && (
          <Box className={classes.discountTag}>
            <p className={clsx(fontClasses.p, classes.tagText)}>
              - {discount}%
            </p>
          </Box>
        )}
      </Box>
    </Link>
  );
};

export default Product;
