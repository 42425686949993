import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";

import { getHomeImages } from "../../api/configPage.js";
import { getFeaturedProducts, getLatestProducts } from "../../api/products.js";
import ProductCarrouselLine from "../../components/ProductCarrouselLine/productCarrouselLine.js";
import styles from "./styles.js";

const Home = () => {
  const classes = styles();
  const [homeImages, setHomeImages] = useState([]);
  const [highLigthsProductsData, setHighLigthsProductsData] = useState([]);
  const [latestProductsData, setLatestProductsData] = useState([]);
  const [someError, setSomeError] = useState(false);

  useEffect(() => {
    getHomeImages()
      .then(function (response) {
        setHomeImages(response.data.data.attributes.home_images);
      })
      .catch(() => {
        setSomeError(true);
      });
  }, []);

  useEffect(() => {
    getLatestProducts()
      .then(function (response) {
        setLatestProductsData(response.data.data);
      })
      .catch(() => {
        setSomeError(true);
      });
  }, [homeImages]);

  useEffect(() => {
    getFeaturedProducts(10, 1)
      .then(function (response) {
        setHighLigthsProductsData(response.data.data);
      })
      .catch(() => {
        setSomeError(true);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const HeaderCarrosuel = () => {
    return (
      <Box className={classes.carrouselHeader}>
        <Carousel showThumbs={false} showArrows={false} showStatus={false}>
          {homeImages &&
            homeImages.map((image, index) => (
              <Box key={index} className={classes.carrouselHeader}>
                <img className={classes.carrouselHeaderImg} src={image} />
              </Box>
            ))}
        </Carousel>
      </Box>
    );
  };

  return (
    <Box>
      <HeaderCarrosuel />
      <Box className={classes.carrouselParentBox}>
        <ProductCarrouselLine
          title="Productos destacados"
          productList={highLigthsProductsData}
        />
        <ProductCarrouselLine
          title="Novedades"
          productList={latestProductsData}
        />
      </Box>
      <Snackbar
        open={someError}
        autoHideDuration={6000}
        onClose={() => {
          setSomeError(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setSomeError(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
          variant={"filled"}
        >
          Presentamos problemas para cargar el contenido, por favor intentelo
          mas tarde.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Home;
