import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import { DIMENSIONS } from "../../constants";
import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  headerWrapper: {
    backgroundColor: "#FCF4E7",
    height: "250px",
    width: "100%",
    display: "flex",
    zIndex: 3000,
    justifyContent: "center",
    alignItems: "center",
    [themeBreakpoints.down("sm")]: {
      height: "100%",
      padding: "20px 0 20px 0",
    },
  },
  headerContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 15,
    [themeBreakpoints.down("md")]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down("sm")]: {
      padding: "20px 10px",
    },
  },
  pathsBox: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
  },
  navigationBox: {
    display: "flex",
    alignItems: "center",
  },
  linkStyle: {
    width: "auto",
    textDecoration: "none",
    color: "#151B25",
    "&:hover": {
      color: "#FFA101",
    },
  },
  descriptionStyle: {
    width: "70%",
    [themeBreakpoints.down("sm")]: {
      width: "100%",
      textAlign: "justify",
    },
  },
}));

export default styles;
