import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getProductsBySeach } from "../../api/products.js";
import DisplayProducts from "../../components/DisplayProductos/index.js";
import SearchHeaderPage from "../../components/SearchHeaderPage/index.js";

const Search = () => {
  const prodcutsBoxRef = useRef(null);
  const navigate = useNavigate();
  const { find } = useParams();
  const [order, setOrder] = useState("price:asc");
  const [index, setIndex] = useState(1);

  const [productsByCategory, setProductsByCategory] = useState();
  const [productsByCategoryMeta, setProductsByCategoryMeta] = useState();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  const handleChangeIndex = (event, page) => {
    setIndex(page);
  };

  useEffect(() => {
    if (find) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setProductsByCategory(null);
      getProductsBySeach(find, index, order)
        .then(function (response) {
          console.log("resposne", response);
          setProductsByCategory(response.data.data);
          setProductsByCategoryMeta(response.data.meta);
        })
        .catch((error) => {
          if (error.response.status === 404) navigate("/not-found");
          else navigate("/server-error");
        });
    }
  }, [index, order]);

  return (
    <Box>
      <SearchHeaderPage search={find} />
      <DisplayProducts
        prodcutsBoxRef={prodcutsBoxRef}
        products={productsByCategory}
        productsMeta={productsByCategoryMeta}
        index={index}
        handleChangeIndex={handleChangeIndex}
        order={order}
        handleChangeOrder={handleChangeOrder}
      />
    </Box>
  );
};

export default Search;
