import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Box } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getCategoryById } from "../../api/category.js";
import { getProductsByMultiCategory } from "../../api/products.js";
import CategoryHeaderPage from "../../components/CategoryHederPage/categoryHeader.js";
import DisplayProducts from "../../components/DisplayProductos/index.js";
import { getCategoryChilds, getCategoryPaths } from "../../utils/categories.js";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Categories = () => {
  const prodcutsBoxRef = useRef(null);
  const navigate = useNavigate();
  const query = useQuery();
  const { id } = useParams();
  const [order, setOrder] = useState();
  const [index, setIndex] = useState();
  const [category, setCategory] = useState();
  const [categoryPath, setCategoryPath] = useState();
  const [categoryChilds, setCategoryChilds] = useState();
  const [productsByCategory, setProductsByCategory] = useState();
  const [productsByCategoryMeta, setProductsByCategoryMeta] = useState();

  //const [someError, setSomeError] = useState(false);

  let indexParam = query.get("index");
  let orderParam = query.get("order");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  //validate params
  useEffect(() => {
    if (validateIndexQueryParam()) setIndex(parseInt(indexParam));
    else {
      indexParam = 1;
      setIndex(parseInt(indexParam));
    }
    if (validateOrderQueryParam()) setOrder(orderParam.replace("_", ":"));
    else {
      orderParam = "price_asc";
      setOrder(orderParam.replace("_", ":"));
    }
  }, [indexParam, orderParam]);

  const putUrlInBrowser = () => {
    window.history.replaceState(
      null,
      null,
      `?index=${index}&order=${order.replace(":", "_")}`
    );
  };

  //Get category information
  useEffect(() => {
    getCategoryById(id)
      .then(function (response) {
        setCategory(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 404) navigate("/not-found");
        else navigate("/server-error");
      });
  }, []);

  const validateIndexQueryParam = () => {
    if (indexParam) return !isNaN(indexParam);
    //falta valdacion de maximo de paginas
    else return false;
  };

  const validateOrderQueryParam = () => {
    return ["price_desc", "price_asc", "name_desc", "name_asc"].includes(
      orderParam
    );
  };

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  const handleChangeIndex = (event, page) => {
    setIndex(page);
  };

  useEffect(() => {
    if (order && index && categoryChilds) {
      putUrlInBrowser();
      setProductsByCategory(null);
      getProductsByMultiCategory(categoryChilds, index, order)
        .then(function (response) {
          setProductsByCategory(response.data.data);
          setProductsByCategoryMeta(response.data.meta);
        })
        .catch((error) => {
          if (error.response.status === 404) navigate("/not-found");
          else navigate("/server-error");
        });
      prodcutsBoxRef.current.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, [index, order, categoryChilds]);

  useEffect(() => {
    if (productsByCategoryMeta) {
      if (index > productsByCategoryMeta.pagination.pageCount) setIndex(1);
    }
  }, [productsByCategoryMeta]);

  useEffect(() => {
    if (category) {
      setCategoryPath(getCategoryPaths(category));
      setCategoryChilds(getCategoryChilds(category));
    }
  }, [category]);

  /*const DisplayProducts = () => {
    return (
      <Box className={classes.categoriesWrapper}>
        <Box className={classes.categoriesContentWrapper}>
          <Box ref={prodcutsBoxRef}>
            <Grid container>
              {!productsByCategoryMeta && (
                <Grid item sm={12} md={6} lg={6}>
                  <Box className={classes.quantityDisplayOrder}>
                    <Skeleton
                      width={250}
                      height={20}
                      sx={{ transform: "scale(1)", marginBottom: "15px" }}
                    />
                  </Box>
                </Grid>
              )}
              {productsByCategoryMeta &&
                productsByCategoryMeta.pagination.total > 0 && (
                  <Grid item sm={12} md={6} lg={6}>
                    <Box className={classes.quantityDisplayOrder}>
                      <p className={fontClasses.p}>
                        Hay {productsByCategoryMeta.pagination.total} productos
                      </p>
                    </Box>
                  </Grid>
                )}
              {!productsByCategoryMeta && (
                <Grid item sm={12} md={6} lg={6}>
                  <Box className={classes.orderSelectBox}>
                    <Skeleton
                      width={250}
                      height={20}
                      sx={{ transform: "scale(1)", marginBottom: "15px" }}
                    />
                  </Box>
                </Grid>
              )}
              {productsByCategoryMeta &&
                productsByCategoryMeta.pagination.total > 0 && (
                  <Grid item sm={12} md={6} lg={6}>
                    <Box className={classes.orderSelectBox}>
                      <p className={fontClasses.p}>
                        <b>Ordenar por: </b>
                      </p>
                      <Select
                        value={order}
                        onChange={handleChangeOrder}
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      >
                        <MenuItem value={"price:asc"}>
                          Precio: más bajo
                        </MenuItem>
                        <MenuItem value={"price:desc"}>
                          Precio: más alto
                        </MenuItem>
                        <MenuItem value={"name:asc"}>
                          Nombre del producto: A - Z
                        </MenuItem>
                        <MenuItem value={"name:desc"}>
                          Nombre del producto: Z - A
                        </MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                )}
            </Grid>
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            <Grid container spacing={4}>
              {!productsByCategory &&
                [1, 2, 3, 4].map((item, index) => (
                  <Grid item key={index} sm={12} md={6} lg={3}>
                    <Skeleton
                      width={"100%"}
                      height={430}
                      sx={{ transform: "scale(1)" }}
                    />
                  </Grid>
                ))}
              {productsByCategory &&
                productsByCategoryMeta.pagination.total > 0 &&
                productsByCategory.map((item, index) => (
                  <Grid item key={index} sm={12} md={6} lg={3}>
                    <Product
                      id={item.id}
                      img={
                        item.attributes.images
                          ? item.attributes.images[0]
                          : "https://res.cloudinary.com/sealabs/image/upload/v1692300837/store.sealabs.cr/template_ebn1jr.png"
                      }
                      title={item.attributes.name}
                      currency={item.attributes.currency.data.attributes.symbol}
                      price={item.attributes.price}
                      discount={item.attributes.discount}
                      createdDate={item.attributes.createdAt}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
          <Box className={classes.paginationBox}>
            {productsByCategoryMeta &&
              productsByCategoryMeta.pagination.total > 0 && (
                <Pagination
                  page={index}
                  count={productsByCategoryMeta.pagination.pageCount}
                  shape="rounded"
                  onChange={handleChangeIndex}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: COLORS.MAIN_LIGTH_COLOR,
                          color: COLORS.MAIN_COLOR,
                        },
                      }}
                    />
                  )}
                />
              )}
            {productsByCategoryMeta &&
              productsByCategoryMeta.pagination.total === 0 && (
                <Box>No hay productos para mostar</Box>
              )}
          </Box>
        </Box>
      </Box>
    );
  };*/

  return (
    <Box>
      <CategoryHeaderPage paths={categoryPath} category={category} />
      <DisplayProducts
        prodcutsBoxRef={prodcutsBoxRef}
        products={productsByCategory}
        productsMeta={productsByCategoryMeta}
        index={index}
        handleChangeIndex={handleChangeIndex}
        order={order}
        handleChangeOrder={handleChangeOrder}
      />
    </Box>
  );
};

export default Categories;
