import { ThemeProvider } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import Footer from "./components/Footer/footer";
import InfoNavbar from "./components/InfoNavbar/infoNavbar";
import Navbar from "./components/Navbar/navbar";
import NavbarCategories from "./components/NavbarCategories/navbarCategories";
import SearchBarNav from "./components/SearchBarNav/searchBarNav";
import Categories from "./pages/categories";
import Home from "./pages/home";
import NotFoundPage from "./pages/notFound";
import ProductPage from "./pages/product";
import Search from "./pages/search";
import ServerErrorPage from "./pages/serverError";
import getTheme from "./theme";

const App = () => {
  const navbarContainerRed = useRef(null);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const searchInputRef = useRef();
  const [openCategories, setOpenCategories] = useState(false);
  const [navbarSearchText, setnavbarSearchText] = useState("");
  const navigate = useNavigate();
  const theme = getTheme();

  const handlerSearchBarState = () => {
    setOpenCategories(false);
    setOpenSearchBar(!openSearchBar);
    searchInputRef.current.focus();
  };

  const handlerNavbarSearchText = (search) => {
    setnavbarSearchText(search);
  };

  const handlerNavbarSearchEnter = () => {
    if (navbarSearchText != "") {
      setOpenSearchBar(false);
      navigate(`/search/${navbarSearchText}`, { reloadDocument: true });
      window.location.reload();
    }
  };

  const handlerOpenCategoriesState = () => {
    setOpenCategories(true);
  };

  const handlerCloseCategoriesState = () => {
    setOpenCategories(false);
  };

  const categories = [
    {
      title: "Accesorios",
      id: "7",
      items: [
        {
          name: "Almohadas",
          id: "10",
        },
        {
          name: "Llaveros",
          id: "11",
        },
      ],
    },
    {
      title: "Figuras",
      id: "6",
      items: [
        {
          name: "Acrilicas",
          id: "8",
        },
        {
          name: "Funkos",
          id: "9",
        },
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <InfoNavbar content="ENVIOS GRATIS DESDE ₡50.000" />
      <Navbar
        categories={categories}
        reference={navbarContainerRed}
        openSearchBar={openSearchBar}
        openCategories={openCategories}
        handlerSearchBarState={handlerSearchBarState}
        handlerOpenCategoriesState={handlerOpenCategoriesState}
      />
      <SearchBarNav
        boxReference={navbarContainerRed}
        openSearchBar={openSearchBar}
        handlerSearchBarState={handlerSearchBarState}
        navbarSearchText={navbarSearchText}
        handlerNavbarSearchText={handlerNavbarSearchText}
        handlerNavbarSearchEnter={handlerNavbarSearchEnter}
        searchInputRef={searchInputRef}
      />
      <NavbarCategories
        categories={categories}
        boxReference={navbarContainerRed}
        openCategories={openCategories}
        handlerCloseCategoriesState={handlerCloseCategoriesState}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category/:id" element={<Categories />} />
        <Route path="/product/:id" element={<ProductPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="/server-error" element={<ServerErrorPage />} />
        <Route path="/search/:find" element={<Search />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
