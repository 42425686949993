export const getCategoryPaths = (category) => {
  const result = [];
  let category_iteration = category.attributes.category_parent.data;
  while (category_iteration != null) {
    const temp = {
      label: category_iteration.attributes.name,
      id: category_iteration.id,
    };
    result.push(temp);
    category_iteration = category_iteration.attributes.category_parent.data;
  }
  return result.reverse();
};

export const getProductPaths = (category) => {
  const result = [];
  let category_iteration = category;
  do {
    const temp = {
      label: category_iteration.attributes.name,
      id: category_iteration.id,
    };
    result.push(temp);
    category_iteration = category_iteration.attributes.category_parent.data;
  } while (category_iteration != null);
  return result.reverse();
};

export const getCategoryChilds = (category) => {
  let result = [];
  result.push(category.id);
  if (category.attributes.category_childs.data != []) {
    return getCategoryChildsAux(
      category.attributes.category_childs.data,
      result
    );
  } else {
    return result;
  }
};

export const getCategoryChildsAux = (category_iteration, result) => {
  category_iteration.forEach((element) => {
    result.push(element.id);
    if (element.attributes.category_childs.data != []) {
      return getCategoryChildsAux(
        element.attributes.category_childs.data,
        result
      );
    } else {
      return result;
    }
  });

  return result;
};
