import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import { DIMENSIONS } from "../../constants";
import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles(() => ({
  footerWrapper: {
    backgroundColor: "#FCF4E7",
    padding: "100PX 0",
    width: "100%",
    display: "flex",
    zIndex: 3000,
    justifyContent: "center",
    alignItems: "center",
  },
  footerContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 15,
    [themeBreakpoints.down("md")]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  categoryTitle: {
    marginBottom: "30px",
  },
  imgLogo: {
    width: "35%",
    [themeBreakpoints.down("md")]: {
      width: "20%",
    },
    [themeBreakpoints.down("sm")]: {
      width: "25%",
    },
  },
  logoText: {
    width: "70%",
    margin: "25px 0",
    [themeBreakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footerBoxRigths: {
    marginTop: "50px",
    borderTop: "solid 1px #151B25",
    paddingTop: "15px",
  },
  footerInfoBox: {
    width: "100%",
    display: "flex",
    [themeBreakpoints.down("md")]: {
      justifyContent: "center",
      marginTop: "15px",
    },
  },
  footerInfoBox2: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    gap: 20,
    [themeBreakpoints.down("md")]: {
      justifyContent: "center",
      marginTop: "15px",
      gap: 20,
    },
  },
}));

export default styles;
