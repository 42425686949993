import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

import Product from "../../components/Product/Product.js";
import { COLORS } from "../../constants";
import fontsStyles from "../../styles/fontStyles.js";
import styles from "./styles.js";

const DisplayProducts = ({
  prodcutsBoxRef,
  products,
  productsMeta,
  index,
  handleChangeIndex,
  order,
  handleChangeOrder,
}) => {
  const classes = styles();
  const fontClasses = fontsStyles();

  return (
    <Box className={classes.categoriesWrapper}>
      <Box className={classes.categoriesContentWrapper}>
        <Box ref={prodcutsBoxRef}>
          {!productsMeta && (
            <Grid container>
              <Grid item sm={12} md={6} lg={6}>
                <Box className={classes.quantityDisplayOrder}>
                  <Skeleton
                    width={250}
                    height={20}
                    sx={{ transform: "scale(1)", marginBottom: "15px" }}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <Box className={classes.orderSelectBox}>
                  <Skeleton
                    width={250}
                    height={20}
                    sx={{ transform: "scale(1)", marginBottom: "15px" }}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {productsMeta && productsMeta.pagination.total > 0 && (
            <Grid container>
              <Grid item sm={12} md={6} lg={6}>
                <Box className={classes.quantityDisplayOrder}>
                  <p className={fontClasses.p}>
                    Hay {productsMeta.pagination.total} productos
                  </p>
                </Box>
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <Box className={classes.orderSelectBox}>
                  <p className={fontClasses.p}>
                    <b>Ordenar por: </b>
                  </p>
                  <Select
                    value={order}
                    onChange={handleChangeOrder}
                    sx={{
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                  >
                    <MenuItem value={"price:asc"}>Precio: más bajo</MenuItem>
                    <MenuItem value={"price:desc"}>Precio: más alto</MenuItem>
                    <MenuItem value={"name:asc"}>
                      Nombre del producto: A - Z
                    </MenuItem>
                    <MenuItem value={"name:desc"}>
                      Nombre del producto: Z - A
                    </MenuItem>
                  </Select>
                </Box>
              </Grid>
            </Grid>
          )}
          {!products && (
            <Box>
              <Box sx={{ marginTop: "15px" }}>
                <Grid container spacing={4}>
                  {[1, 2, 3, 4].map((item, index) => (
                    <Grid item key={index} xs={12} sm={12} md={6} lg={3}>
                      <Skeleton
                        width={"100%"}
                        height={430}
                        sx={{ transform: "scale(1)" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
          {products && productsMeta.pagination.total > 0 && (
            <Box>
              <Box sx={{ marginTop: "15px" }}>
                <Grid container spacing={4}>
                  {products &&
                    productsMeta.pagination.total > 0 &&
                    products.map((item, index) => (
                      <Grid item key={index} xs={12} sm={12} md={6} lg={3}>
                        <Product
                          id={item.id}
                          img={
                            item.attributes.images
                              ? item.attributes.images[0]
                              : "https://res.cloudinary.com/sealabs/image/upload/v1692300837/store.sealabs.cr/template_ebn1jr.png"
                          }
                          title={item.attributes.name}
                          currency={
                            item.attributes.currency.data.attributes.symbol
                          }
                          price={item.attributes.price}
                          discount={item.attributes.discount}
                          createdDate={item.attributes.createdAt}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
              <Box className={classes.paginationBox}>
                <Pagination
                  page={index}
                  count={productsMeta.pagination.pageCount}
                  shape="rounded"
                  onChange={handleChangeIndex}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: COLORS.MAIN_LIGTH_COLOR,
                          color: COLORS.MAIN_COLOR,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          )}
          {productsMeta && productsMeta.pagination.total == 0 && (
            <Box className={classes.notFoundBox}>
              <p className={classes.notFoundIcon}>
                <ErrorOutlineIcon sx={{ fontSize: "80px" }} />
              </p>
              <p className={fontClasses.h4}>
                No se encontraron productos para mostar
              </p>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DisplayProducts;
