import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from "@material-ui/styles";

import breakpoints from "../../theme/breakpoints";

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles(() => ({
  buttonMainStyle: {
    textDecoration: "none",
    backgroundColor: "transparent",
    width: "auto",
    padding: "10px 20px",
    height: "45px",
    border: "solid 2px #151B25",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
      opacity: "0.9",
    },
    [themeBreakpoints.down("sm")]: {
      gap: 10,
      padding: "10px 15px",
    },
  },

  buttonMainStyleDisabled: {
    textDecoration: "none",
    backgroundColor: "#e0e0e0",
    padding: "10px 20px",
    width: "100%",
    height: "45px",
    border: "solid 2px #e0e0e0",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    cursor: "not-allowed",
    transition: "all 0.3s ease",
    opacity: 1,
    color: "black",
  },
  iconButtonBox: {
    padding: "5px",
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: "50%",
    border: "splid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
